import React, { type Dispatch, type SetStateAction, useEffect } from "react";
import { cn } from "~/core/styles/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { createPortal } from "react-dom";

export interface ToastProps {
  text: string;
  isActive: boolean;
  setIsActive?: Dispatch<SetStateAction<boolean>>;
  position?: "left" | "right";
  hasTimeout?: boolean;
}

const POSITIONS = {
  left: "left-14",
  right: "right-14",
};

export const Toast: React.FC<ToastProps> = ({
  text,
  isActive,
  setIsActive,
  position = "right",
}) => {
  useEffect(() => {
    if (setIsActive) {
      setTimeout(() => {
        if (isActive === true) {
          setIsActive(false);
        }
      }, 2000);
    }
  });

  return (
    <>
      {createPortal(
        <AnimatePresence>
          {isActive && (
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className={cn(
                "custom-shadow bg-default-soft fixed bottom-14 right-0 z-50 w-full max-w-[150px] rounded-xs  border-2 border-primary p-4 text-xs",
                POSITIONS[position]
              )}
            >
              {text}
            </motion.div>
          )}
        </AnimatePresence>,
        document.body
      )}
    </>
  );
};
