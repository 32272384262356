import { useTheme } from "next-themes";
import { useCurrentSubscription } from "~/core/hooks/use-stripe-current-subscription";
import { useUser } from "@clerk/nextjs";
import { type NavProps, type NavContext, type NavItemGroup } from "./types";
import { cn } from "~/core/styles/helpers";
import { useEffect, useState } from "react";
import { NavButtons } from "~/core/components/Layout/PageLayout/Header/HeaderNav/HeaderNavButtons";
import { HeaderNavLink } from "~/core/components/Layout/PageLayout/Header/HeaderNav/HeaderNavLinks";

export const HeaderNav = (props: NavProps) => {
  const {
    itemGroups,
    forceDirection = "row",
    showLinksIcon = true,
    className,
    bgColor,
    classNameLinkItem: linkItemClassName,
  } = props;

  const { resolvedTheme } = useTheme();
  const { isSignedIn, user } = useUser();
  const { planStatic } = useCurrentSubscription({ user });

  const localProps: NavContext = {
    ...props,
    isSignedIn,
    planStatic,
    showLinksIcon,
    resolvedTheme,
  };

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    typeof window !== "undefined" && setIsClient(true);
  }, []);

  const isRow = forceDirection === "row";

  const STYLES = {
    row: "flex-row items-center",
    column: "flex-col items-start",
  };
  const baseStyle = cn(STYLES[forceDirection], "flex");

  const renderNavItems = (itemGroup: NavItemGroup, iMain: number) => {
    if (itemGroup.type === "links") {
      return (
        <div className={cn(baseStyle, "w-full  ", isRow ? "gap-6" : "gap-2")}>
          {itemGroup.ids.map((itemId, i) => (
            <div key={`${itemGroup.type}-${iMain}`} className="w-full">
              <HeaderNavLink
                {...props}
                {...localProps}
                itemId={itemId}
                key={`link-${itemId}-${i}`}
                bgColor={bgColor}
                classNameItem={cn(linkItemClassName, "@lg:!min-w-[0px]")}
              />
            </div>
          ))}
        </div>
      );
    } else if (itemGroup.type === "buttons") {
      return (
        <div
          className={cn(baseStyle, isRow ? "gap-6" : "gap-4")}
          key={`${itemGroup.type}-${iMain}`}
        >
          {isClient &&
            itemGroup.ids.map((itemId, i) => (
              <NavButtons
                {...localProps}
                itemId={itemId}
                key={`button-${itemId}-${i}`}
              />
            ))}
        </div>
      );
    }
  };

  return (
    <div
      className={cn(
        baseStyle,
        "relative",
        isRow ? "gap-16" : "gap-10",
        className
      )}
    >
      {itemGroups?.map(renderNavItems)}
    </div>
  );
};
