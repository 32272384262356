export const animationBaseProps = {
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true },
};

export const transition = {
  normal: {
    type: "spring",
    damping: 15,
    stiffness: 50,
  },
};

export const variants = {
  fadeIn: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: transition.normal,
    },
  },
  fromLeft: {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: transition.normal,
    },
  },
  fromRight: {
    hidden: { opacity: 0, x: 20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: transition.normal,
    },
  },
  fromBottom: {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: transition.normal,
    },
  },
  container: {
    visible: {
      transition: { staggerChildren: 0.2, delayChildren: 0.04 },
    },
  },
  words: {
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: transition.normal,
    },
    hidden: {
      opacity: 1,
      x: -20,
      y: 10,
      transition: transition.normal,
    },
  },
};
