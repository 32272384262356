import { useSubscription } from "use-stripe-subscription";
import {
  type stripeSubscriptionProps,
  type useCurrentSubscriptionProps,
} from "~/features/components/Pricing/types";
import { getPlanLimit } from "~/core/utils/getPlanLimit";
import { PLANS, type planStaticProps } from "../utils/static/plans";

export const useCustomSubscription = () =>
  useSubscription() as stripeSubscriptionProps;

export const useCurrentSubscription = (props?: useCurrentSubscriptionProps) => {
  const stripeSubscription = useCustomSubscription();
  const { subscription, products } = stripeSubscription;

  const planDynamic =
    products &&
    products.find(
      (product) => product.product.id === subscription?.plan?.product
    );

  const planStatic = (
    planDynamic
      ? PLANS.find((p) => p.id === planDynamic.product.name)
      : PLANS[0]
  ) as planStaticProps;

  const planLimits =
    props &&
    getPlanLimit({
      planStatic,
      ...props,
    });

  return { planDynamic, planStatic, planLimits };
};
