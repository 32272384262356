import { cva } from "class-variance-authority";
import { useState } from "react";
import { cn } from "~/core/styles/helpers";
import {
  type UiElement,
  type allColors,
  type neutralColors,
} from "~/core/types";
import { Button, type ButtonBaseProps } from "~/coreUI/Basic/Button/Button";
import { Check, Copy } from "~/coreUI/Basic/icons";
import { Toast, type ToastProps } from "~/coreUI/Overlays/Toast/Toast";

interface CopyToClipboardProps extends UiElement {
  toastPosition?: ToastProps["position"];
  paths?: (string | null | undefined)[];
  showText?: boolean;
  textValue: string;

  bgColor?: neutralColors;
  color?: allColors;
  buttonProps: ButtonBaseProps;
}

const styles = cva("", {
  variants: {
    bgColor: {
      default: "bg-default",
      soft: "bg-default-soft",
    },
  },
});

export const CopyToClipboard = (props: CopyToClipboardProps) => {
  const {
    toastPosition = "left",
    className,
    showText = false,
    textValue,
    bgColor = "default",
    buttonProps,
    color,
  } = props;
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    void navigator.clipboard.writeText(textValue);
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 3000);
  };

  return (
    <div
      className={cn(
        " relative flex w-full items-center gap-3 overflow-hidden ",
        className
      )}
    >
      {showText && (
        <div
          className={cn(
            "overflow-clip overflow-x-hidden whitespace-nowrap rounded-2xs  p-2 px-4 text-2xs",
            styles({ bgColor })
          )}
        >
          {textValue}
        </div>
      )}
      <Button
        icon={isActive ? <Check /> : <Copy />}
        onClick={handleClick}
        aria-label="Copy link"
        {...buttonProps}
        color={color}
        bgColor={bgColor}
      />

      <Toast
        text="Link copied !"
        isActive={isActive}
        setIsActive={setIsActive}
        position={toastPosition}
      />
    </div>
  );
};
