import Link from "next/link";
import { LogoBrandLight } from "~/coreUI/Basic/icons/LogoBrandLight";
import { Container } from "~/coreUI/Layout/Container/Container";
import { ButtonTheme } from "~/coreUI/Basic/ButtonCustom/ButtonTheme/ButtonTheme";
import { LogoBrandDark } from "~/coreUI/Basic/icons/LogoBrandDark";
import { useUser } from "@clerk/nextjs";
import { Logo } from "~/coreUI/Basic/icons/Logo";
import { Popover } from "~/coreUI/Overlays/Popover/Popover";
import { useCurrentBreakpoint } from "~/core/hooks/use-current-breakpoint";
import { useEffect, useState } from "react";
import { type PageLayoutHeaderProps } from "../types";
import { ButtonHamburger } from "~/coreUI/Basic/ButtonCustom/ButtonHamburger/ButtonHamburger";
import { Button } from "~/coreUI/Basic/Button/Button";
import { HeaderNav } from "./HeaderNav/HeaderNav";
import { Dialog } from "~/core/components/Overlays/Dialog/Dialog";

export const LayoutHeader = (props: PageLayoutHeaderProps) => {
  const LOGO_BADGE = "beta";
  const showBadge = false;
  const { paths, isPublic = false, headerActions } = props;

  const { isSignedIn } = useUser();
  const breakpoint = useCurrentBreakpoint();
  const isMobile =
    breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md";

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      document.body.style.overflow = "unset";
      setIsModalOpen(false);
    }
  }, [isMobile]);

  return (
    <>
      <header className=" primary relative z-50 flex  w-full flex-col items-start justify-start self-stretch  text-lg ">
        <Container
          className=" border-b-[1px] border-grey-light dark:border-grey-dark"
          innerClassName="flex h-14 w-full max-w-7xl flex-row items-center justify-between"
        >
          <div className=" ml-2 flex items-center justify-start gap-4">
            <Link href={isSignedIn ? "/dashboard" : "/"} aria-label="Home">
              <Logo className="w-9 sm:hidden" />
              <LogoBrandLight className="hidden dark:sm:!block" />
              <LogoBrandDark className="hidden dark:!hidden sm:!block" />
            </Link>

            {showBadge && (
              <div className=" rounded-full bg-grey-light px-2 text-2xs text-grey dark:bg-grey-dark ">
                {LOGO_BADGE}
              </div>
            )}
          </div>
          <div className="relative flex w-full items-center justify-end gap-6 @container">
            <HeaderNav
              paths={paths}
              isPublic={isPublic}
              itemGroups={headerActions.mainNavigation}
              showLinksIcon={false}
              bgColor="default"
              classNameLinkItem="!text-grey"
            />

            {!isMobile && (
              <Popover
                className="hidden md:!block"
                classNamePanel="overflow-visible"
                key="hamburger-menu"
                popoverSize="md"
                bgColor="default"
                popoverButtonOutlineColor="soft"
                button={(open) => (
                  <Button
                    isHeadless
                    size="sm"
                    as="div"
                    icon={<ButtonHamburger isOpen={open} className="primary" />}
                  />
                )}
                panel={() => (
                  <div className="flex flex-col gap-10">
                    <HeaderNav
                      bgColor="default"
                      paths={paths}
                      isPublic={isPublic}
                      itemGroups={headerActions.overlays}
                      forceDirection="column"
                      showLinksIcon
                    />

                    <ButtonTheme
                      mode="extended"
                      buttonBgColor="soft"
                      popoverBgColor="default"
                    />
                  </div>
                )}
              />
            )}

            <Button
              icon={
                <ButtonHamburger isOpen={false} className={"m-auto mt-[1px]"} />
              }
              className="!text-primary lg:hidden"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                document.body.style.overflow = "unset";
              }}
              bgColor="default"
            />
            <Dialog
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              size="md"
            >
              <HeaderNav
                {...props}
                itemGroups={headerActions.overlays}
                forceDirection="column"
                bgColor="soft"
              />
              <div className="w-full justify-center">
                <ButtonTheme
                  popoverPlacement="bottom"
                  buttonBgColor="default"
                  popoverBgColor="default"
                />
              </div>
            </Dialog>
          </div>
        </Container>
      </header>
    </>
  );
};
