import { isFilled } from "@prismicio/helpers";
import { type LinkField } from "@prismicio/types";
import { type PageDocument } from "prismicio-types";
import type * as prismicT from "@prismicio/types";

export const getLinkTitle = (link: LinkField) => {
  if (
    isFilled.contentRelationship<
      "page",
      string,
      Pick<PageDocument["data"], "title">
    >(link)
  ) {
    return link?.data?.title;
  }
};

export const isLinkDataDefined = (
  link: prismicT.LinkField
): link is prismicT.LinkField & {
  data: NonNullable<prismicT.LinkField>;
} => {
  return link && link !== null;
};
