import useBreakpoint from "use-breakpoint";

export const BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;
export type BreakpointKey = keyof typeof BREAKPOINTS;

export function useCurrentBreakpoint() {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  return breakpoint;
}
