export type planStaticProps = {
  id: "Free" | "Hobby" | "Pro";
  name: string;
  datasetsLimit: number;
  viewsLimit: number;
};

export const PLANS: planStaticProps[] = [
  {
    id: "Free",
    name: "Free",
    datasetsLimit: 3,
    viewsLimit: 6,
  },
  {
    id: "Hobby",
    name: "Hobby",
    datasetsLimit: 10,
    viewsLimit: 10,
  },
  {
    id: "Pro",
    name: "Pro",
    datasetsLimit: 500,
    viewsLimit: 100,
  },
];
