import { useState } from "react";
import { LayoutFooter } from "~/core/components/Layout/PageLayout/Footer/Footer";
import { LayoutHeader } from "~/core/components/Layout/PageLayout/Header/Header";
import { useCurrentBreakpoint } from "~/core/hooks/use-current-breakpoint";
import { type headerActionsProps, type PageLayoutProps } from "./types";

export const PageLayout = (props: PageLayoutProps) => {
  const { showFooter = true, children, footer, isHeaderCompact } = props;
  const [isOpen, setIsOpen] = useState(false);
  const breakpoint = useCurrentBreakpoint();
  const isMobile =
    breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md";

  const headerActions: headerActionsProps = {
    mainNavigation: isMobile
      ? [
          {
            type: "buttons",
            ids: ["theme", "share", "sign-in", "user"],
          },
        ]
      : [
          {
            type: "links",
            ids: isHeaderCompact ? [] : ["dashboard", "example", "pricing"],
          },
          {
            type: "buttons",
            ids: ["share", "theme", "plan", "sign-in", "user"],
          },
        ],
    overlays: [
      {
        type: "links",
        ids: ["dashboard", "example", "pricing", "home"],
      },
      {
        type: "buttons",
        ids: ["plan", "sign-in", "user"],
      },
    ],
  };

  return (
    <div className="bg-default">
      <LayoutHeader
        {...props}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        headerActions={headerActions}
      />
      {children}
      {footer && showFooter && <LayoutFooter {...props} />}
    </div>
  );
};
