import { type planStaticProps } from "~/core/utils/static/plans";

type getPlanLimitProps = {
  planStatic: planStaticProps;
  datasetsCount?: number;
  viewsCount?: number;
};

export type planLimitProps = {
  hasDatasetsRemaining: boolean;
  datasetsRemaining: number;
  hasViewsRemaining: boolean;
  viewsRemaining: number;
};

export const getPlanLimit = ({
  planStatic,
  datasetsCount,
  viewsCount,
}: getPlanLimitProps): planLimitProps => {
  const datasetsRemaining = planStatic.datasetsLimit - (datasetsCount ?? 0);
  const viewsRemaining = planStatic.viewsLimit - (viewsCount ?? 0);

  return {
    hasDatasetsRemaining: datasetsRemaining ? datasetsRemaining > 0 : false,
    datasetsRemaining,
    hasViewsRemaining: viewsRemaining ? viewsRemaining > 0 : false,
    viewsRemaining,
  };
};
