import { motion, type Transition, type SVGMotionProps } from "framer-motion";

interface Props extends SVGMotionProps<SVGSVGElement> {
  isOpen?: boolean;
  strokeWidth?: string | number;
  transition?: Transition;
}

export const ButtonHamburger = ({
  isOpen = false,
  width = 16,
  height = 16,
  strokeWidth = 2.5,
  transition,

  ...props
}: Props) => {
  const variant = isOpen ? "opened" : "closed";
  const top = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: 45,
      translateY: 1.5,
    },
  };
  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  };
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -1.5,
    },
  };
  const lineProps2 = {
    stroke: "currentColor",
    strokeWidth: strokeWidth as number,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    transition,
  };

  const unitHeight = 4;
  const unitWidth = (unitHeight * (width as number)) / (height as number);

  return (
    // <div className="my-auto flex items-center justify-center">
    <motion.svg
      viewBox={`0 0 ${unitWidth} ${unitHeight}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={width}
      height={height}
      {...props}
    >
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="0.5"
        y2="0.5"
        variants={top}
        {...lineProps2}
      />
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="2"
        y2="2"
        variants={center}
        {...lineProps2}
      />
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="3.5"
        y2="3.5"
        variants={bottom}
        {...lineProps2}
      />
    </motion.svg>
    // </div>
  );
};
