import { Home, Edit, Eye } from "~/coreUI/Basic/icons";
import { Logo } from "~/coreUI/Basic/icons/Logo";
import { type NavLinkProps, type HeaderNavLinkProps } from "./types";
import { Button } from "~/coreUI/Basic/Button/Button";
import { cn } from "~/core/styles/helpers";

export const HeaderNavLink = (props: HeaderNavLinkProps) => {
  const {
    isSignedIn,
    showLinksIcon,
    isPublic,
    itemId,
    bgColor,
    classNameItem,
  } = props;

  const NAV_LINKS: NavLinkProps[] = [
    {
      id: "home",
      object: {
        icon: <Home />,
        href: "/",
        enforceIsPublic: true,
        label: "Home",
        status: "enabled",
      },
    },
    {
      id: "dashboard",
      object: {
        icon: <Edit />,
        href: "/dashboard",
        enforceIsSignedIn: true,
        label: "Dashboard",
        status: "enabled",
      },
    },
    {
      id: "pricing",
      object: {
        label: "Pricing",
        icon: (
          <div className="">
            <Logo />
          </div>
        ),
        status: "enabled",
        href: "/pricing",
      },
    },
    {
      id: "example",
      object: {
        icon: (
          <div className="">
            <Eye />
          </div>
        ),
        href: "https://renaud-cheval.notion.site/Sport-tracker-3ba3900e92094789be87bcc78bf66c1a",
        label: "Example",
        status: "enabled",
      },
    },
  ];

  return (
    <>
      {NAV_LINKS.map(
        ({
          object: { enforceIsSignedIn, enforceIsPublic, icon, ...passThrough },
          id,
        }) => {
          const checkSignin = enforceIsSignedIn ? isSignedIn : true;
          const checkPublic = enforceIsPublic ? isPublic : true;

          return itemId === id && checkSignin && checkPublic ? (
            <Button
              {...passThrough}
              key={id}
              isHeadless
              className={cn("w-full text-xs", classNameItem)}
              iconPosition="start"
              icon={showLinksIcon ? icon : null}
              bgColor={bgColor}
              size="sm"
              as="a"
            />
          ) : null;
        }
      )}
    </>
  );
};
