import { type ReactNode } from "react";
import { Check, Dropdown } from "~/coreUI/Basic/icons";
import { Button } from "~/coreUI/Basic/Button/Button";
import { CopyToClipboard } from "~/coreUI/Basic/ButtonCustom/CopyToClipboard/CopyToClipboard";
import { ButtonTheme } from "~/coreUI/Basic/ButtonCustom/ButtonTheme/ButtonTheme";
import { dark } from "@clerk/themes";
import { SignInButton, UserButton } from "@clerk/nextjs";
import { type NavContext } from "./types";
import { generateLink } from "~/core/utils/generateLink";

type NavButtonsProps = {
  id: "share" | "theme" | "plan" | "sign-in" | "user";
  object: ReactNode;
};

interface NavLinksProps extends NavContext {
  itemId?: string | null;
}

export const NavButtons = (props: NavLinksProps) => {
  const { isSignedIn, planStatic, resolvedTheme, paths, isPublic, itemId } =
    props;

  const NAV_BUTTONS: NavButtonsProps[] = [
    {
      id: "share",
      object:
        paths && isPublic ? (
          <CopyToClipboard
            toastPosition="right"
            textValue={generateLink({
              paths: paths,
            })}
            bgColor="soft"
            buttonProps={{ size: "sm", bgColor: "soft" }}
          />
        ) : null,
    },
    {
      id: "theme",
      object: <ButtonTheme mode="compact" className="" />,
    },
    {
      id: "plan",
      object:
        isSignedIn && planStatic && planStatic.name !== "Free" ? (
          <Button
            size="xs"
            color="secondary"
            shapeStyle="outline"
            label={planStatic.name}
            iconPosition="start"
            icon={<Check />}
            as="div"
          />
        ) : null,
    },
    {
      id: "sign-in",
      object: !isSignedIn ? (
        <SignInButton mode="modal" redirectUrl="/dashboard" key="sign in">
          <Button
            icon={<Dropdown className="relative shrink-0 overflow-hidden" />}
            label="Log In"
            size="sm"
            className="min-w-[110px]"
            shapeStyle="outline"
          />
        </SignInButton>
      ) : null,
    },
    {
      id: "user",
      object: (
        <UserButton
          appearance={{
            baseTheme: resolvedTheme === "dark" ? dark : undefined,
          }}
          afterSignOutUrl="/"
        />
      ),
    },
  ];

  return (
    <>
      {NAV_BUTTONS.map(({ id, object }) => {
        return (itemId === undefined || itemId === id) && object ? (
          <div key={id} className="my-auto">
            {object}
          </div>
        ) : null;
      })}
    </>
  );
};
