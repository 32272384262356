import { Mail } from "~/coreUI/Basic/icons/Mail";
import { Twitter } from "~/coreUI/Basic/icons/Twitter";
import { LogoMonochromeLight } from "~/coreUI/Basic/icons/LogoMonochromeLight";
import { LogoMonochromeDark } from "~/coreUI/Basic/icons/LogoMonochromeDark";
import { isFilled } from "@prismicio/helpers";
import { type ReactElement } from "react";
import { type PageLayoutProps } from "../types";
import { getLinkTitle, isLinkDataDefined } from "~/core/utils/prismic";
import { Button } from "~/coreUI/Basic/Button/Button";
import { motion } from "framer-motion";
import { animationBaseProps, variants } from "~/core/styles/animations";

const SOCIAL_LINKS_ICONS: Record<"Twitter" | "Mail", ReactElement> = {
  Twitter: <Twitter className="relative shrink-0 overflow-hidden " />,
  Mail: <Mail className="relative shrink-0 " />,
};

export const LayoutFooter = (props: PageLayoutProps) => {
  if (props.footer) {
    const { copyright, linksinternal, linkssocial } = props.footer.data;

    return (
      <motion.div
        className="bg-default-soft secondary flex flex-col items-center justify-start self-stretch px-0 pb-10 pt-24 text-center text-[1rem] "
        {...animationBaseProps}
        variants={variants.container}
      >
        <div className="flex w-full flex-wrap items-center justify-center gap-12 px-2 py-0">
          {linksinternal &&
            linksinternal.map(({ link }) => (
              <motion.div
                key={getLinkTitle(link)}
                variants={variants.fromBottom}
              >
                <Button
                  className="w-fit !min-w-[0px]"
                  as="prismicLink"
                  field={link}
                  label={getLinkTitle(link)}
                  isHeadless
                  bgColor="soft"
                  color="secondary"
                />
              </motion.div>
            ))}
        </div>
        {linkssocial && (
          <div className="flex flex-col items-center justify-start gap-3 px-0 pb-12 pt-12  text-[1.25rem]">
            <div className="colored flex h-[3.33rem] shrink-0 flex-row items-center justify-center gap-[2.44rem]">
              {linkssocial.map(({ link, label }) => {
                return (
                  label &&
                  isFilled.link(link) &&
                  isLinkDataDefined(link) && (
                    <motion.div
                      variants={variants.fromBottom}
                      key={getLinkTitle(link)}
                    >
                      <Button
                        bgColor="default"
                        as="a"
                        href={link.url}
                        icon={SOCIAL_LINKS_ICONS[label]}
                        size="lg"
                        color="secondary"
                        aria-label={label}
                      />
                    </motion.div>
                  )
                );
              })}
            </div>
          </div>
        )}
        <motion.div className="pb-8" variants={variants.fromBottom}>
          <LogoMonochromeLight className="hidden dark:!block" />
          <LogoMonochromeDark className="dark:hidden" />
        </motion.div>

        {copyright && (
          <motion.div
            className="relative self-stretch text-2xs text-grey"
            variants={variants.fromBottom}
          >
            {copyright}
          </motion.div>
        )}
      </motion.div>
    );
  } else {
    return null;
  }
};
