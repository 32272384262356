import { motion } from "framer-motion";
import { Dialog as DialogComponent } from "@headlessui/react";
import { cn } from "~/core/styles/helpers";
import { Closesquare } from "~/coreUI/Basic/icons";
import { Button } from "~/coreUI/Basic/Button/Button";
import { bgStyles, borderStyles, shadowStyles } from "~/core/styles/cvaStyles";
import { type baseColors } from "~/core/types";
import { cva } from "class-variance-authority";

export const panelStyles = cva("", {
  variants: {
    size: {
      sm: "",
      md: "lg:max-h-[400px] min-w-[250px] max-h-[80vh]",
      lg: "max-h-[80vh]  max-w-[450px] text-sm",
    },
  },
});

interface DialogProps {
  children: React.ReactNode;
  topLeftElement?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  size: "sm" | "md" | "lg";
  color?: baseColors;
}

export const Dialog = ({
  children,
  topLeftElement,
  isOpen,
  onClose,
  color = "primary",
  ...styleProps
}: DialogProps) => {
  return (
    <DialogComponent
      as={motion.div}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
      }}
      open={isOpen}
      onClose={onClose}
      key="dialog"
      className={cn(
        color,
        "default fixed inset-0 z-50 bg-opacity-50 opacity-100 backdrop-blur-md"
      )}
    >
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <DialogComponent.Panel
          as="div"
          className={cn(
            "overflow-scroll !bg-opacity-[0.99] p-8 backdrop-blur-xl ",
            "has-shadow flex w-full max-w-md flex-col items-start rounded-md border-2",
            borderStyles({ color: "default" }),
            bgStyles({ bgColor: "soft" }),
            shadowStyles({ ...styleProps }),
            panelStyles({ ...styleProps })
          )}
        >
          <div className=" flex w-full items-center">
            {topLeftElement}
            <Button
              onClick={onClose}
              className="ml-auto p-2"
              icon={<Closesquare className="" />}
              bgColor="soft"
              size="md"
              aria-label="Close"
            />
          </div>
          <div className="primary flex w-full flex-col  gap-6 sm:gap-10 sm:p-8 ">
            {children}
          </div>
        </DialogComponent.Panel>
      </div>
    </DialogComponent>
  );
};
